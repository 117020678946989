import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { GetServerSideProps } from 'next';
import FeaturedNews from '@components/Home/FeaturedNews';
import FeaturedLastNews from '@components/Home/FeaturedLastNews';

import { api, mimir, site, validatesResponse } from '@/services/api';
import { TimeLine } from '@/types/types';
import { AdContainer } from '@/components/Layout/ItemListLastNews/styles';
import { HighLightProducts, NavBarContentData, TimeLineApi } from '@/shared/types/home.types';
import { AxiosResponse } from 'axios';
import { SchemaType } from '@/components/Internals/InternalSchema';
import { editorialCategoryProps } from '@/components/Home/FeaturedEditorialCategory';
import { getCache, hasCache, setCache } from '@/utils/cache';
import FeaturedProducts from '@/components/Home/FeaturedProducts';
import Layout from '@/components/Layout';
import { getEditorialCategory, getSponsoredCategory, sponsoredCategoriesMarketUri, sponsoredCategoriesSecurityUri } from '@/lib/sponsors';

export type Vitrine = {
  tipo: string;
  titulo: string;
  categoria: string;
  autor: string;
  url: string;
  imagem: {
    url: string;
    blurhash: string;
  };
  data: string;
  idade: string;
};

export type HeadTags = {
  tag: string;
  content: string;
  attr: {
    rel: string;
    href: string;
    type: string | undefined;
    title: string | undefined;
    name: string | undefined;
    content: string | undefined;
    property: string | undefined;
    'http-equiv': string | undefined;
  };
};

export type FeaturedTimelineData = {
  nome: string;
  titulo: string;
  vitrine: Vitrine[];
  timeline: LastNewsItems;
};

interface LastNewsItems {
  itens: TimeLine[];
  paginacao: string;
}

interface FeaturedNewsType {
  navBarData: any;
  tagsData: HeadTags[];
  segmentacoes: [];
  timelineData: FeaturedTimelineData;
  schema?: SchemaType;
  homeData: {
    data: {
      produtos: HighLightProducts[];
    };
  };
}

interface FeaturedNewsApi {
  data: {
    vitrine: Vitrine[];
    timeline: TimeLine[];
  };
  ssr: HeadTags[];
  segmentacoes: [];
  headers: {
    'Cache-control': string;
    Expires: string;
  };
}

// eslint-disable-next-line no-shadow
export enum PublicationTypes {
  'noticias' = '/noticias',
  'analises' = '/analises',
  'dicas' = '/dicas',
  'tutoriais' = '/tutoriais',
  'o-que-e' = '/o-que-e',
  'materias' = '/materias',
  'colunas' = '/colunas',
  'editoriais' = '/editoriais',
  'listas' = '/listas',
  'artigos' = '/artigos',
}

export default function Categories({ homeData, navBarData, tagsData, segmentacoes, timelineData, schema = null }: FeaturedNewsType): JSX.Element {
  const AdsTypeGenerators = dynamic(() => import('@/components/Ads/Google/AdsTypeGenerators'), { ssr: false });

  const router = useRouter();
  const { categorias } = router.query;

  const isPublicationType = Boolean(PublicationTypes[categorias.toString()]);

  const singleCategory = Array.isArray(categorias) ? categorias[0] : categorias;

  const editorialCategory = getEditorialCategory(singleCategory);
  const sponsoredCategory = getSponsoredCategory(singleCategory);

  const getRouteType = () => {
    if (typeof categorias === 'string' && Object.keys(editorialCategoryProps).includes(categorias))
      return editorialCategoryProps[categorias as keyof typeof editorialCategoryProps].routeType;
    if (categorias === 'analises') return 'tipo';
    return isPublicationType ? 'tipoDePublicacao' : 'categoria';
  };

  const getRoute = () => {
    if (typeof categorias === 'string' && Object.keys(editorialCategoryProps).includes(categorias))
      return editorialCategoryProps[categorias as keyof typeof editorialCategoryProps]?.route || '';
    if (categorias === 'analises') return 'analise';
    return categorias.toString();
  };

  return (
    <Layout layoutData={{ segmentacoes, tagsData, navBarData, schema }} dataLayer={{ contentType: 'list' }}>
      <FeaturedNews featuredNewsData={timelineData.vitrine} editorialCategory={editorialCategory} />
      {categorias === 'produtos' && <FeaturedProducts featuredProductsData={homeData.data.produtos} />}
      {timelineData?.vitrine?.length > 3 && (
        <AdContainer noPadding billboard>
          <AdsTypeGenerators type="billboard/main/listing" />
        </AdContainer>
      )}

      <FeaturedLastNews
        featuredLastNewsData={timelineData.timeline}
        rootPage={categorias}
        textTitle={timelineData.titulo}
        sponsoredCategory={sponsoredCategory}
        headingTag="h1"
        routeType={getRouteType()}
        route={getRoute()}
      />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async context => {
  let timeline = null;
  let data = null;
  let statusCode = 0;
  let treatedResponse;

  const { categorias } = context.query;

  const getCategoryList = async () => {
    if (hasCache('categoryList')) return getCache('categoryList') as { [key: string]: number };
    const categoryList = await api.get<{ status: number; data: { [key: string]: number } }>('/artigo/categorias/');
    setCache({ key: 'categoryList', value: categoryList.data.data, time: '1d' });
    return categoryList.data.data;
  };

  const categoryList = await getCategoryList();
  const isPublicationType = Boolean(PublicationTypes[categorias.toString()]);
  if (typeof categorias === 'string' && !categoryList[categorias] && !isPublicationType) {
    data = {
      notFound: true,
      props: {},
    };
    return data;
  }

  const { host } = context.req.headers;

  const getEndpoint = () => {
    if (categorias === 'ctup') return '/timelines/ultimas/id/155778?merge=155790/';
    if (categorias === 'analises') return '/timelines/ultimas/tipo/analise/';
    if (typeof categorias === 'string' && (sponsoredCategoriesMarketUri.includes(categorias) || sponsoredCategoriesSecurityUri.includes(categorias)))
      return `/timelines/ultimas/categoria/arvore/${categorias}/`;
    return isPublicationType ? `/timelines/ultimas/tipo/artigo/${categorias}/` : `/timelines/ultimas/categoria/${categorias}/`;
  };

  const homeReq = site.get<FeaturedNewsApi>(`/${categorias}/`);
  const timelinesReq = mimir.get<TimeLineApi>(getEndpoint());
  const responseMenuReq = api.get<NavBarContentData>(`/componentes/menu/`);
  const promises = { homeReq, timelinesReq, responseMenuReq };
  const promiseData: {
    homeReq: AxiosResponse<FeaturedNewsApi>;
    timelinesReq: AxiosResponse<TimeLineApi>;
    responseMenuReq: AxiosResponse<NavBarContentData>;
  } = {
    homeReq: null,
    timelinesReq: null,
    responseMenuReq: null,
  };

  await Promise.allSettled(Object.values(promises)).then(responses =>
    // eslint-disable-next-line consistent-return
    responses.forEach((response, index) => {
      if (response.status === 'fulfilled') {
        switch (index) {
          case 0:
            promiseData.homeReq = (response as PromiseFulfilledResult<AxiosResponse<FeaturedNewsApi>>).value;
            treatedResponse = validatesResponse(
              response.value,
              false,
              false,
              '',
              host,
              null,
              isPublicationType ? `/tipo-publicacao/${categorias}/` : `/categoria/${categorias}/`
            );
            data = treatedResponse.data;
            statusCode = treatedResponse.statusCode;
            break;
          case 1:
            promiseData.timelinesReq = (response as PromiseFulfilledResult<AxiosResponse<TimeLineApi>>).value;
            break;
          case 2:
            promiseData.responseMenuReq = (response as PromiseFulfilledResult<AxiosResponse<NavBarContentData>>).value;
            break;
          default:
            break;
        }
      }
      if (response.status === 'rejected') {
        treatedResponse = validatesResponse(response.reason, true, true, categorias);
        data = treatedResponse.data;
        statusCode = treatedResponse.statusCode;
        if (index === 0 && (data.redirect || data.notFound)) return data;
      }
    })
  );

  if (data.redirect || data.notFound) return data;

  timeline = promiseData.timelinesReq;
  const homeData = data;
  const responseMenu = promiseData.responseMenuReq;
  const navBarData = responseMenu.data.data;
  const tagsData = data.ssr;
  const schema = data?.schema || null;
  const timelineData = timeline.data.data;

  if (categorias === 'ctup') {
    timelineData.titulo = 'Tudo sobre CTUP';
  }

  context.res.setHeader('Cache-Control', data.headers['Cache-control']);
  context.res.setHeader('Expires', data.headers.Expires);
  context.res.statusCode = statusCode;

  return {
    props: {
      homeData,
      tagsData,
      navBarData,
      schema,
      timelineData,
    },
  };
};
